<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CUSTOMER_ORG_TYPES } from './store'
import { ROUTES as CUSTOMER_ORG_ROUTES } from './router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../supplier-organization/router'
import { ROUTES as LEAD_ORG_ROUTES } from '../lead-organization/router'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE } from '@/const'

import OrganizationDetailMixin from '../../../components/organization/mixins/OrganizationDetailMixin'
import CustomerContactList from '../../../contact/contact/customer-contact/CustomerContactList'

export default {
  name: 'CustomerOrganizationDetail',
  mixins: [OrganizationDetailMixin],
  components: {
    'component-contact-list': CustomerContactList
  },
  data () {
    return {
      CUSTOMER_ORG_ROUTES,
      title: 'Customer organizations',
      routeNameList: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST,
      routeDocumentNameList: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DOCUMENTS,
      orgType: ORGANIZATION_CUSTOMER_TYPE_CODE,
      showDownloadDocumentsButton: true,
      showCompanyRegistryButton: true,
      routeOrganizationInspectionList: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_INSPECTIONS_LIST,
      editRolePerm: 'organization_customerorganization_update'
    }
  },
  computed: {
    ...mapGetters({
      ...CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.DETAIL.GETTERS,
      groups: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.groups,
      organizationform: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.organizationform,
      typologyChoices: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.typologyChoices,
      employeeCategoryChoices: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.employeeCategoryChoices,
      orgTypeChoices: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.orgTypeChoices
    })
  },
  methods: {
    ...mapMutations({
      setGroups:
        CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setGroups
    }),
    ...mapActions({
      ...CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.DETAIL.ACTIONS,
      getOrganizationList: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getList,
      getGroups: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getGroups,
      getOrganizationform: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getOrganizationform,
      getTypologychoices: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getTypologychoices,
      getEmployeecategorychoices: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getEmployeecategorychoices,
      getOrgtypechoices: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getOrgtypechoices
    }),
    goToDetailPage (evt) {
      this.orgFilters = false
      this.showDuplicatedForm = false
      if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_LEAD_CODE)) {
        this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_SUPPLIER_CODE } })
      }
    }
  }
}
</script>
